import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommaSeparatePipe } from './comma-separate/comma-separate.pipe';

@NgModule({
  declarations: [
    CommaSeparatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommaSeparatePipe
  ]
})
export class PipesModule { }
