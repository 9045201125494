import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-booking-dialog',
  templateUrl: './confirm-booking-dialog.component.html',
  styleUrls: ['./confirm-booking-dialog.component.scss']
})
export class ConfirmBookingDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  // Handle the 'Cancel' button click
  onCancel(): void {
    this.dialogRef.close(false);
  }

  // Handle the 'Confirm' button click
  onConfirm(): void {
    this.dialogRef.close(true);
  }
}