import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MainCompComponent } from './components/main-comp/main-comp.component';
import { SharedModule } from './shared/shared.module';
import { MainInterceptor } from './interceptor/main.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    MainCompComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DirectivesModule,
    PipesModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot(
      {
        preventDuplicates: true,
        maxOpened: 1
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
