import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaNum]'
})
export class AlphaNumDirective {

  constructor() { }

  @HostListener('keypress', ['$event']) onkeydown(event: { charCode: number; }) {
    return (event.charCode >= 65 && event.charCode < 91) || (event.charCode >= 97 && event.charCode < 123) || (event.charCode >= 48 && event.charCode <= 57);
  }
}
