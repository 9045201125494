import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() maxPage: any;
  @Input() currentPage: any;
  @Output() pageChange = new EventEmitter();
  values: any;

  constructor() { }

  ngOnInit() {
  }

  keyDownFunction(event: { keyCode: number; }) {
    if (event.keyCode === 13) {
      this.keypressevent();
    }
  }

  keypressevent(event?: any) {
    if (this.currentPage > this.maxPage) {
      this.currentPage = this.maxPage;
    } else if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.navigateToPage(this.currentPage);
  }

  // tslint:disable-next-line: variable-name
  navigateToPage(page_number?: any) {
    this.pageChange.emit(page_number);
  }


}
