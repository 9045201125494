import { HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAdminToken } from '../Utils/utils';

@Injectable()

export class MainInterceptor implements HttpInterceptor {

  constructor(private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url) {
      let authReq;
      if (req.url.includes('api/space/imageUpload') && req.url.indexOf('api/space/imageUpload') === -1) {
        authReq = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
      } 
      if (req.url.includes('api/space/updateProfilePic') && req.url.indexOf('api/user/updateProfilePic') === -1) {
        authReq = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
      }
      else {
        authReq = req.clone({});
      }
      if (getAdminToken()) {
        authReq = authReq.clone({
          setHeaders: {
            'adminToken': `${getAdminToken()}`
          }
        });
      }
      return next.handle(authReq).pipe(tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
			if (event.body.responseCode === 403 || event.body.responseCode === 401) {
              if (!event.body.success) {
                localStorage.clear();
                this.router.navigate(['/login']);
              }
            }
          }
        }, error => {
	      if (error.error.status === 403) {
            if (error.error.error === 'Unauthorised request') {
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          }
        }
      ));
    } else {
     return next.handle(req)
    }
  }
}
