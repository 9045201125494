import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestOptions } from '@angular/http';
import { ValidationUpdateData } from '../../models/validation-update.model';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private loggedInStatus = false;
  currentAdmin: any = {};
  httpOptions:any;

  constructor(private http: HttpClient) {
    this.currentAdmin = JSON.parse(localStorage.getItem('currentAdmin') || '{}');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'adminToken': this.currentAdmin && this.currentAdmin['adminToken'] ? this.currentAdmin['adminToken'] : ''
      })
    };
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
  }

  get isLoggedIn() {
    return this.loggedInStatus;
  }

  getToken() {
    return localStorage.getItem('currentAdmin');
  }
  isAdminLoggedIn() {
    return this.getToken() !== null;
  }

  getAdminDetails(email: any, password: any) {
    // post these details to API server return user info if correct
    return this.http.post<any>(`${environment.api_url}login`, { email, password });
  }

  logout() {
    let item = JSON.parse(localStorage.getItem('currentAdmin') || '{}');
    let adminToken = item.adminToken;
    return this.http.post<any>(`${environment.api_url}logout`, {
      adminToken
    }).pipe(map(admin => {
      if (admin) {
        localStorage.clear();
        this.loggedInStatus = false;
      }
      return admin;
    }));
  }

  /**
 * Booking API Section
 */

  updateReservationDetails(reservationDetails: any) {
    return this.http.post<any>(`${environment.api_url}reservation/updateReserveRequest`, reservationDetails);
  }

  /**
   * User API Section
   */

  getAllUser(search: any) {
    return this.http.get<any>(`${environment.api_url}user/list?search=${search}`);
  }

  getUserDetail(userId: number) {
    return this.http.get(`${environment.api_url}user/viewUserProfile/${userId}`);
  }

  deleteUser(params: any) {
    return this.http.post(`${environment.api_url}user/deleteUser`, { params });
  }

  changeAdminStatus(data: any) {
    return this.http.post(`${environment.api_url}user/changeAdminVerified`, { data });
  }

  getUserOverviewData() {
    return this.http.get(`${environment.api_url}user/showUserKpi`);
  }


  /**
   * Space API Section
   */

  getAllSpaces(search: any) {
    return this.http.get<any>(`${environment.api_url}space/listAllSpace?search=${search}`);
  }

  getSpaceDetail(spaceId: number) {
    return this.http.get<any>(`${environment.api_url}space/viewSpaceBrief/${spaceId}`);
  }

  changeSpaceStatus(data: { spaceId: any; adminStatus: any; }) {
    return this.http.post(`${environment.api_url}space/changeSpaceAdminStatus`, data);
  }

  updateValidation(data: ValidationUpdateData) {
    return this.http.post(`${environment.api_url}space/validationUpdate`, data);
  }

  approveSpacePrice(data: { spaceId: any; }) {
    return this.http.post(`${environment.api_url}space/approveSpacePrice`, data);
  }


  updateSpace(data: any) {
    //console.log('data', data);
    return this.http.post(`${environment.api_url}space/updateSpace`, data);
  }

  deleteSpace(params: any) {
    return this.http.post(`${environment.api_url}space/deleteSpace`, params);
  }

  imageUpload(params: FormData, options:any = RequestOptions) {
    return this.http.post(`${environment.api_url}space/imageUpload`, params, options);
  }

  public imageUploadSpace(formData: FormData | File, options?: undefined): Observable<any> {
    return this.http.post(`${environment.api_url}space/imageUpload`, formData);
  }

  updateProfilePic(params: FormData, options:any = RequestOptions) {
    return this.http.post(`${environment.api_url}user/updateProfilePic`, params, options);
  }

  getAll(pageNumber: any, body: any) {
    return this.http.post(`${environment.api_url}space/listAllSpace?pageNumber=${pageNumber}`, body)
  }

  getAllNew(pageNumber: any, body: any) {
    //console.log(pageNumber, body);
    return this.http.post(`${environment.api_url}space/newSpaces?pageNumber=${pageNumber}`, body);
  }

  getAllDeactivated(pageNumber: any, body: any) {
    return this.http.post(`${environment.api_url}space/deactivatedSpaces?pageNumber=${pageNumber}`, body);
  }

  getAllValidation(pageNumber: any, body: any) {
    return this.http.post(`${environment.api_url}space/spaceValidation?pageNumber=${pageNumber}`, body);
  }

  getAllActivated(pageNumber: any, body: any) {
    //console.log(pageNumber, body);
    return this.http.post(`${environment.api_url}space/activatedSpaces?pageNumber=${pageNumber}`, body);
  }

  getAllCommercial(pageNumber: any, body: any) {
    //console.log(pageNumber, body);
    return this.http.post(`${environment.api_url}space/commercialSpaces?pageNumber=${pageNumber}`, body);
  }

  getManagedSpaces(search: string) {
    return this.http.post(`${environment.api_url}space/getManagedSpaces`, search);
  }

  transferListing(spaceId: string, newUserId: string) {
    return this.http.post(`${environment.api_url}space/transferListing`, {
      spaceId,
      newUserId
    });
  }

  getUserById(userId: string) {
    return this.http.get(`${environment.api_url}space/getUserById/${userId}`);
  }

  getAllSpaceFilter(pageNumber: any, body: any) {
    return this.http.post(`${environment.api_url}space/listAllSpace?pageNumber=${pageNumber}`, body)
  }

  addSpaceLabel(data: { spaceId: any; label: any; type: string; }) {
    return this.http.post(`${environment.api_url}space/addSpaceLabel`, data);
  }

  addSpaceLocLabel(data: { spaceId: any; tag: any; type: string; }) {
    return this.http.post(`${environment.api_url}space/spaceLocationTag`, data);
  }

  addSpaceCondLabel(data: { spaceId: any; tag: any; type: string; }) {
    return this.http.post(`${environment.api_url}space/spaceConditionTag`, data);
  }

  addKeeperLabel(data: { spaceId: any; tag: any; type: string; }) {
    return this.http.post(`${environment.api_url}space/keeperResponseTag`, data);
  }

  addSolutionLabel(data: { spaceId: any; tag: any; type: string; }) {
    return this.http.post(`${environment.api_url}space/addSolutionTag`, data);
  }

  addFollowUp(data: { spaceId: any; followUpDate: string; }) {
    return this.http.post(`${environment.api_url}space/addFollowUp`, data);
  }

  /*fetchFollowUpData(pageno: any) {
    return this.http.get(`${environment.api_url}space/fetchAllFollowUpSpace?pageNumber=${pageno}`);
  }*/

  getBookingOverviewData() {
    return this.http.get(`${environment.api_url}space/showStorageSpaceKpi`);
  }

  getStorageOverviewData() {
    return this.http.get(`${environment.api_url}space/showStorageSpaceKpi`);
  }

  getParkingOverviewData() {
    return this.http.get(`${environment.api_url}space/showParkingSpaceKpi`);
  }

  getPDPOverviewData() {
    return this.http.get(`${environment.api_url}space/showPickdropSpaceKpi`);
  }

  getLabelData(pageno: any, data: any) {
    return this.http.post(`${environment.api_url}space/searchlabel?pageNumber=${pageno}`, data);
  }

  //Financial API
  getBillingList(startDate: string, endDate: string, pageNumber: any) {
    return this.http.get(`${environment.api_url}finance/getBillings?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
  }

  getUpcomingBillingList(startDate: string, endDate: string, pageNumber: any) {
    return this.http.get(`${environment.api_url}finance/getUpcomingBillings?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
  }

  //Financial API
  getPaidBillingList(startDate: string, endDate: string, pageNumber: any) {
    return this.http.get(`${environment.api_url}finance/getPaidBillings?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
  }

  //Financial API
  getPaymentsMadeInfo(startDate: string, endDate: string, pageNumber: any) {
    return this.http.get(`${environment.api_url}transaction/getPaymentsMadeInfo?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
  }

  getDepositPaymentsMadeInfo(startDate: string, endDate: string, pageNumber: any) {
    return this.http.get(`${environment.api_url}transaction/getDepositPaymentsMadeInfo?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
  }

  updateDepositRefundDate(paymentId: string, depositRefundDate: Date): Observable<any> {
    // Ensure depositRefundDate is sent as a proper date
    return this.http.put(`${environment.api_url}transaction/updateDepositRefundDate/${paymentId}`, {
      depositRefundDate: depositRefundDate.toISOString()  // Convert to ISO string format if necessary
    });
  }

  updateBooking(paymentId: string, appliedBookingId: string): Observable<{ responseCode: number, responseMessage: string, result?: any }> {
    return this.http.put<{ responseCode: number, responseMessage: string, result?: any }>(
      `${environment.api_url}transaction/updateBooking/${paymentId}`,
      { appliedBookingId }
    );
  }

  getBookingKPI() {
    return this.http.get(`${environment.api_url}booking/bookingKPIs`);
  }

  getBillingListExport(startDate: string, endDate: string) {
    return this.http.get(`${environment.api_url}finance/getBillings?startDate=${startDate}&endDate=${endDate}`);
  }

  getBillingDetails(id: any) {
    return this.http.get(`${environment.api_url}finance/getetails?id=${id}`);
  }

  updatePaymentManually(id: { d: any; }) {
    return this.http.post(`${environment.api_url}finance/updatePaymentManually?bookingId=${id.d}`, {
      "bookingId": id.d
    });
  }

  // Common Api Call for get, put, delete, post, patch*****************************
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { params: params })
      .pipe(catchError(this.formatErrors));
  }
  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }
  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }
  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body), this.httpOptions
    ).pipe(catchError(this.formatErrors));
  }
  delete(path: any): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(catchError(this.formatErrors));
  }
  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
