import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlphaNumDirective } from './alpha-num/alpha-num.directive';
import { AlphaOnlyDirective } from './alpha-only/alpha-only.directive';
import { CommaSeparateDirective } from './comma-separate/comma-separate.directive';
import { NumberOnlyDirective } from './number-only/number-only.directive';
import { GooglePlacesDirective } from './google-places.directive';
import { ImgCompressorDirective } from './img-compressor.directive';

@NgModule({
  declarations: [
    AlphaNumDirective,
    AlphaOnlyDirective,
    CommaSeparateDirective,
    NumberOnlyDirective,
    GooglePlacesDirective,
    ImgCompressorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlphaNumDirective,
    AlphaOnlyDirective,
    CommaSeparateDirective,
    NumberOnlyDirective,
    GooglePlacesDirective,
    ImgCompressorDirective
  ]
})
export class DirectivesModule { }
