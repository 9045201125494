import { Component, OnInit } from '@angular/core';
import { imageUploadUrl } from 'src/app/Utils/utils';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from 'src/app/services/api/api.service';
import { Headers, RequestOptions } from '@angular/http';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  public idPicUploader: FileUploader = new FileUploader({ url: imageUploadUrl, itemAlias: 'photo' });
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.idPicUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      const formData = new FormData();
      formData.append('userPhoto', file._file);
      const headers = new Headers({ 'content-type': 'multipart/formData' });
      const options = new RequestOptions({ headers });
      this.apiService.imageUpload(formData, options).subscribe((response: any) => {
        if (response.responseCode === 200) {
          console.log("->", response)
        }
      }, (reject) => {

        // console.log(reject)
      });
    };
  }

}
