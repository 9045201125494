import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentAdmin: any;
  role!: string;
  isMenuVisible: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private router: Router) {
    this.isMenuVisible = window.innerWidth > 768;
  }

  ngOnInit() {
    this.currentAdmin = JSON.parse(localStorage.getItem('currentAdmin') || '{}');
    this.role = this.currentAdmin['role'];
    this.updateMenuVisibility(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateMenuVisibility(event.target.innerWidth);
  }

  status: boolean = false;
  togglerMenu() {
    this.document.body.classList.toggle('mini-sidebar');
    this.isMenuVisible = !this.isMenuVisible;
  }

  logout() {
    this.apiService.logout().subscribe(data => {
      if (data) {
        localStorage.removeItem('currentAdmin');
        this.router.navigate(['/login']);
      }
    });
  }

  private updateMenuVisibility(width: number) {
    this.isMenuVisible = width > 768;
  }
}