import { Directive } from '@angular/core';

@Directive({
  selector: '[appCommaSeparate]'
})
export class CommaSeparateDirective {

  constructor() { }

}
