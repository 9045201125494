import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface MenuItem {
  name: string;
  imageClass: string;
  roles?: string[];
  route?: string;
  otherSection?: MenuItem[];
  subSection?: MenuItem[];
  showOtherOption?: boolean;
  sectionSelected?: boolean;
  showSubSectionOption?: boolean;
  subSectionSelected?: boolean;
}

const ROLE_GROUPS = {
  admin: ['Admin', 'Superadmin'],
  all: ['Admin', 'Superadmin', 'Manager', 'Head', 'Ops', 'Sales', 'Marketing', 'Overview'],
  allDep: ['Admin', 'Superadmin', 'Manager', 'Head', 'Ops', 'Sales', 'Marketing'],
  head: ['Admin', 'Superadmin', 'Head'],
  sales: ['Admin', 'Superadmin', 'Head', 'Sales'],
  salesMarketing: ['Admin', 'Superadmin', 'Head', 'Sales', 'Marketing'],
  OpsMngt: ['Admin', 'Superadmin', 'Head', 'Manager'],
  LeeveOps: ['Admin', 'Superadmin', 'Head', 'Manager', 'Ops'],
};

const MASTER_MENU = [
  {
    name: 'Dashboard',
    imageClass: 'fas fa-tachometer-alt',
    roles: ROLE_GROUPS.all,
    otherSection: [
      {
        name: "Overview",
        imageClass: "",
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Dashboard', imageClass: '', route: '/main/dashboard' },
          { name: 'Task List', imageClass: '', route: '/main/dashboard/actions' }
        ]
      },
      {
        name: "Segment KPIs",
        imageClass: "",
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Keeper', imageClass: '', route: '/main/dashboard/keeper' },
          { name: 'Leever', imageClass: '', route: '/main/dashboard/leever' }
        ]
      }
    ]
  },
  {
    name: 'Accounts',
    imageClass: 'fas fa-user-circle',
    roles: ROLE_GROUPS.all,
    otherSection: [
      {
        name: 'Users',
        imageClass: '',
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Overview', imageClass: '', route: '/main/users-overview' },
          { name: 'Email Verified Users', imageClass: '', route: '/main/verified-users' },
          { name: 'Email Unverified Users', imageClass: '', route: '/main/unverified-users' },
          { name: 'Deleted/ Deactivated', imageClass: '', route: '/main/users/deleted' }
        ]
      },
      {
        name: 'Verification',
        imageClass: '',
        roles: ROLE_GROUPS.admin,
        subSection: [
          { name: 'ID Verification', imageClass: '', route: '/main/validation/for-validation' },
          { name: 'Keeper', imageClass: '', route: '/main/validation/for-keeper' },
          { name: 'Leever', imageClass: '', route: '/main/validation/for-leever' },
          { name: 'Agent', imageClass: '', route: '/main/validation/for-agent' },
          { name: 'Refer & Earn', imageClass: '', route: '' },
          { name: 'Verified Accounts', imageClass: '', route: '/main/validation/validated-accounts' }
        ]
      }
    ]
  },
  {
    name: 'Spaces',
    imageClass: 'fas fa-warehouse',
    roles: ROLE_GROUPS.all,
    otherSection: [
      {
        name: "Overview",
        imageClass: "",
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Overview', imageClass: '', route: '/main/space-overview', roles: ROLE_GROUPS.all },
          { name: 'Commercial Spaces', imageClass: '', route: '/main/commercial-spaces', roles: ROLE_GROUPS.allDep }
        ]
      },
      {
        name: "Space Management",
        imageClass: "",
        roles: ROLE_GROUPS.allDep,
        subSection: [
          { name: 'New Spaces', imageClass: '', route: '/main/spaces', roles: ROLE_GROUPS.allDep },
          { name: 'Validation', imageClass: '', route: '/main/follow-up', roles: ROLE_GROUPS.allDep },
          { name: 'Deactivated Spaces', imageClass: '', route: '/main/deactivated-spaces', roles: ROLE_GROUPS.allDep },
          { name: 'Activated Spaces', imageClass: '', route: '/main/activated-spaces', roles: ROLE_GROUPS.allDep },
          { name: 'Space Transfer', imageClass: '', route: '/main/space-transfer', roles: ROLE_GROUPS.OpsMngt },
          { name: 'Listing Inquiries', imageClass: '', route: '/main/listing-inquiries', roles: ROLE_GROUPS.OpsMngt },
        ]
      }
      /*{
        name: 'Discrepancy Report',
        imageClass: '',
        route: '/main/space-discrepancy'
      },
      {
        name: 'All Spaces',
        imageClass: '',
        route: '/main/space-quality'
      },
      {
        name: 'Profiles',
        imageClass: '',
        subSection: [
          { name: 'Active', imageClass: '', route: '/main/space-profile/space-active' },
          { name: 'Price Adjustments', imageClass: '', route: '/main/space-profile/space-price' },
          { name: 'Deactivated', imageClass: '', route: '/main/space-profile/space-deactive' }
        ]
      }*/
    ]
  },
  {
    name: 'Transaction',
    imageClass: 'fas fa-exchange-alt',
    roles: ROLE_GROUPS.all,
    otherSection: [
      {
        name: 'KPIs',
        imageClass: '',
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Overview', imageClass: '', route: '/main/booking-overview' }
        ]
      },
      {
        name: 'Reservations & Inquiries',
        imageClass: '',
        roles: ROLE_GROUPS.allDep,
        subSection: [
          { name: 'Space Inquiries', imageClass: '', route: '/main/space-inquiries' },
          { name: 'Received Requests', imageClass: '', route: '/main/reservation-request' },
          { name: 'Approved Requests', imageClass: '', route: '/main/reservation-request-approved' },
          { name: 'Rejected Requests', imageClass: '', route: '/main/reservation-request-rejected' },
          { name: 'Cancelled Requests', imageClass: '', route: '/main/reservation-request-delete' },
          { name: 'Expired and Deleted Requests', imageClass: '', route: '/main/expired-request' }
        ]
      },
      {
        name: 'Booking',
        imageClass: '',
        roles: ROLE_GROUPS.allDep,
        subSection: [
          { name: 'Upcoming', imageClass: '', route: '/main/upcoming-bookings' },
          { name: 'On-going', imageClass: '', route: '/main/bookings' },
          { name: 'Completed & Terminated', imageClass: '', route: '/main/expired-bookings' },
          { name: 'Recent & Soon Completion', imageClass: '', route: '/main/recent-and-soon' },
          { name: 'All', imageClass: '', route: '/main/all-bookings' }
        ]
      },
      {
        name: 'Bills',
        imageClass: '',
        roles: ROLE_GROUPS.LeeveOps,
        subSection: [
          { name: 'Due Bills', imageClass: '', route: '/main/financial/invoicing' },
          { name: 'Upcoming Bills', imageClass: '', route: '/main/financial/upcoming' },
          { name: 'Paid Bills', imageClass: '', route: '/main/financial/paid' },
          { name: 'All Bills', imageClass: '', route: '/main/financial/all-bills' },
          { name: 'Billing Statement', imageClass: '', route: '/main/financial/billing-statement' },
          { name: 'Add Bill', imageClass: '', route: '/main/financial/add-billing', roles: ROLE_GROUPS.head }
        ]
      },
      {
        name: 'Payments & Payouts',
        imageClass: '',
        roles: ROLE_GROUPS.LeeveOps,
        subSection: [
          { name: 'Payments', imageClass: '', route: '/main/financial/payments' },
          { name: 'Payout', imageClass: '', route: '/main/compensation', roles: ROLE_GROUPS.head },
          { name: 'Keeper Statements', imageClass: '', route: '/main/financial/account-statement', roles: ROLE_GROUPS.head },
          { name: 'Insurance', imageClass: '', route: '/main/financial/insurance', roles: ROLE_GROUPS.head },
          { name: 'Deposits', imageClass: '', route: '/main/financial/deposits', roles: ROLE_GROUPS.head },
          { name: 'Maya Invoices', imageClass: '', route: '/main/financial/maya-invoice', roles: ROLE_GROUPS.head },
          { name: 'PayMongo Invoices', imageClass: '', route: '/main/financial/paymongo-invoice', roles: ROLE_GROUPS.head }
        ]
      }
    ]
  },
  {
    name: 'Referrals & Discounts',
    imageClass: 'fas fa-tags',
    roles: ROLE_GROUPS.all,
    otherSection: [
      {
        name: 'Discounts',
        imageClass: '',
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Overview', imageClass: '', route: '/main/discounts/discounts-overview', roles: ROLE_GROUPS.all },
          { name: 'Generate Voucher', imageClass: '', route: '/main/discounts/generate-voucher', roles: ROLE_GROUPS.admin }
        ]
      },
      {
        name: 'Listing Referrals',
        imageClass: '',
        roles: ROLE_GROUPS.all,
        subSection: [
          { name: 'Referral Overview', imageClass: '', route: '/main/listing-referrals/overview', roles: ROLE_GROUPS.all },
          { name: 'Create Referral Account', imageClass: '', route: '/main/listing-referrals/create', roles: ROLE_GROUPS.admin },
          { name: 'Referral Statement', imageClass: '', route: '/main/listing-referrals/statement', roles: ROLE_GROUPS.admin }
        ]
      }
    ]
  },
  {
    name: 'Community',
    imageClass: 'fas fa-object-group',
    roles: ROLE_GROUPS.head,
    otherSection: [
      {
        name: "Reviews",
        imageClass: "",
        roles: ROLE_GROUPS.head,
        subSection: [
          { name: 'About Keeper Reviews', imageClass: '', route: '/main/reviews', roles: ROLE_GROUPS.head }
        ]
      }
    ]
  },
  {
    name: 'Sales',
    imageClass: 'fas fa-user-tie',
    roles: ROLE_GROUPS.allDep,
    otherSection: [
      {
        name: "KPIs",
        imageClass: "",
        roles: ROLE_GROUPS.allDep,
        subSection: [
          { name: 'KPI Overview', imageClass: '', route: '/main/leads-overview', roles: ROLE_GROUPS.all },
          { name: 'Funnel Overview', imageClass: '', route: '/main/funnel', roles: ROLE_GROUPS.all },
          { name: 'Segment Overview', imageClass: '', route: '/main/segments', roles: ROLE_GROUPS.all },
          { name: 'Pull Efforts Status', imageClass: '', route: '/main/pull-efforts', roles: ROLE_GROUPS.all }
        ]
      },
      {
        name: "Sales Management",
        imageClass: "",
        roles: ROLE_GROUPS.allDep,
        subSection: [
          { name: 'Add Incoming Lead', imageClass: '', route: '/main/add-pull-lead', roles: ROLE_GROUPS.allDep },
          { name: 'Add Push Lead', imageClass: '', route: '/main/add-lead', roles: ROLE_GROUPS.sales },
          { name: 'Daily Plan', imageClass: '', route: '/main/daily-plan', roles: ROLE_GROUPS.sales },
          { name: 'Leads Overview', imageClass: '', route: '/main/leads', roles: ROLE_GROUPS.sales }
        ]
      }
    ]
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  sidebarMenuSection: any[] = [];
  currentAdmin: any = '';
  role: string = '';

  constructor(private router: Router) { }

  ngOnInit() {
    this.currentAdmin = JSON.parse(localStorage.getItem('currentAdmin') || '{}');
    this.role = this.currentAdmin['role'] || '';

    // **Filter Main Sections** based on role
    // **Filter Main Sections** based on role
    this.sidebarMenuSection = MASTER_MENU
      .filter(menu => menu.roles.includes(this.role))
      .map(menu => ({
        ...menu,
        // **Filter Other Sections** based on role
        otherSection: Array.isArray(menu.otherSection)
          ? (menu.otherSection as MenuItem[]) // Ensure TypeScript treats it as an array
            .filter((sub: MenuItem) => {
              const subRoles: string[] = sub.roles && Array.isArray(sub.roles)
                ? sub.roles
                : sub.roles ? [sub.roles]
                  : menu.roles && Array.isArray(menu.roles) ? menu.roles
                    : menu.roles ? [menu.roles]
                      : [];

              return subRoles.includes(this.role);
            })


            .map((sub: MenuItem) => ({
              ...sub,
              subSection: Array.isArray(sub.subSection)
                ? sub.subSection.filter((subItem: MenuItem) => {
                  const subItemRoles = subItem.roles
                    ? (Array.isArray(subItem.roles) ? subItem.roles : [subItem.roles])
                    : sub.roles || [];
                  return subItemRoles.includes(this.role);
                })
                : []
            }))
          : []
      }));
  }

  menuSectionClick(selectedOption: MenuItem, index: number, subIndex?: number) {
    this.sidebarMenuSection.forEach((element: MenuItem, _index: number) => {
      if (index === _index) {
        element.sectionSelected = !element.sectionSelected;

        // Ensure otherSection expands, even if no subSection exists
        if (element.otherSection && element.otherSection.length) {
          element.showOtherOption = !element.showOtherOption;

          element.otherSection.forEach((subElement: MenuItem, ind: number) => {
            if (subIndex !== undefined && subIndex === ind) {
              subElement.subSectionSelected = !subElement.subSectionSelected;
              subElement.showSubSectionOption = !subElement.showSubSectionOption;
              element.sectionSelected = true;
              element.showOtherOption = true;
            } else {
              subElement.subSectionSelected = false;
              subElement.showSubSectionOption = false;
            }
          });
        } else if (element.route) {
          // If no otherSection exists, navigate directly
          this.router.navigate([element.route]);
        }
      } else {
        element.sectionSelected = false;
        element.showOtherOption = false;
      }
    });
  }
}
