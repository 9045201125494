import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaOnly]'
})
export class AlphaOnlyDirective {

  element: HTMLInputElement
  constructor(elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  @HostListener('keypress', ['$event']) onkeydown(event: { charCode: number; }) {
    return (event.charCode >= 65 && event.charCode < 91) || (event.charCode >= 97 && event.charCode < 123) || event.charCode === 32;
  }

}
