import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

  constructor() { }

  @HostListener('keypress', ['$event']) onkeydown(event: { charCode: number; }) {
    return (event.charCode >= 48 && event.charCode <= 57);
}

}
